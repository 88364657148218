export const idLabel = 'id';
export const elementsLabel = 'elements';
export const elementLabel = 'element';
export const normalIdentifierLabel = 'normal';
export const quotedIdentifierLabel = 'quoted';
export const linkLabel = 'link';
export const serviceLabel = 'service';
export const providerLabel = 'provider';
export const groupLabel = 'group';
export const edgeLabel = 'edge';
export const nodeLabel = 'node';
export const attrLabel = 'attr';
export const attrNameLabel = 'attrName';
export const attrValueLabel = 'attrValue';
export const attrListLabel = 'attrList';
export const directedArrowLabel = 'directedArrow';
export const bidirectionalArrowLabel = 'bidirectionalArrow';
export const deepArrowLabel = 'deepArrow';
export const bidirectionalDeepArrowLabel = 'bidirectionalDeepArrow';
